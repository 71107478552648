<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white">
      <div class="oSwiper" v-for="(item, index) in swiperData" :key="index">
        <van-swipe-item
          ><img :src="item.url" alt="" style="width: 100%"
        /></van-swipe-item>
      </div>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  components: {},
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    finishedText: {
      type: String,
      default: "没有更多了",
    },
    autoplay: {
      type: Number,
      default: 3000,
    },
    swiperData: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onLoad() {
      console.log(111);
      this.$emit("onLoad");
    },
  },
};
</script>

<style></style>
