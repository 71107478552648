<template>
  <div>
    <van-calendar
      v-model="show"
      :type="type"
      :color="color"
      :min-date="minDateFormat"
      :max-date="maxDateFormat"
      @closed="close"
      @confirm="onConfirm"
      :position="position"
      :poppable="poppable"
      :show-mark="showMark"
      :show-title="showTitle"
      :show-confirm="true"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { Calendar, CellGroup } from "vant";

Vue.use(Calendar);
export default {
  watch: {
    value(newValue, oldValue) {
      console.log(newValue, 44);
      this.show = newValue;
    },
    show(newValue, oldValue) {},
  },
  components: {},
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    showMark: {
      type: Boolean,
      default: true,
    },
    poppable: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "bottom",
    },
    color: {
      type: String,
      default: "#1989fa",
    },
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "single",
    },
    placeholder: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 最小可选时间，Date 类型 或 null
    minDate: {
      type: [Date, Object],
      default: null,
    },
    // 最大可选时间，Date 类型 或 null
    maxDate: {
      type: [Date, Object],
      default: null,
    },
  },
  created() {
    console.log(this.minDate, this.maxDate);
  },
  data() {
    return {
      show: this.value,
    };
  },
  computed: {
    minDateFormat() {
      const time = this.minDate;
      // moment() 为当前时间
      const dateFormat = time || moment().toDate();
      console.log(dateFormat);
      return dateFormat;
    },
    maxDateFormat() {
      const time = this.maxDate;
      // moment() 为当前时间
      const dateFormat = time || moment().add("6", "M").toDate();
      return dateFormat;
    },
  },
  methods: {
    close() {
      this.$emit("updateValue");
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      if (this.type == "range") {
        let oDate = [];
        oDate[0] = date[0];
        oDate[1] = date[1];
        this.$emit("change", oDate);
      } else {
        let oDate = date;
        this.$emit("change", oDate);
      }
    },
  },
};
</script>

<style></style>
