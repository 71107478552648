const routes = [
  //活动签到-详情
  {
    path: "/futureNeighbourhood/activitySignInDetail",
    name: "activitySignInDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activitySignInDetail.vue",
      ], resolve),
  },
  //活动签到
  {
    path: "/futureNeighbourhood/activitySignIn",
    name: "activitySignIn",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activitySignIn.vue",
      ], resolve),
  },
  // 活动管理
  //活动列表
  {
    path: "/futureNeighbourhood/activityList",
    name: "activityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityList.vue",
      ], resolve),
  },
  //活动参与人列表
  {
    path: "/futureNeighbourhood/activityUserList",
    name: "activityUserList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityUserList.vue",
      ], resolve),
  },
  // 活动报名
  //活动列表
  {
    path: "/futureNeighbourhood/applyActivity",
    name: "applyActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/index"], resolve),
  },
  // 时间银行->志愿者申请 applyVolunteer
  {
    path: "/futureNeighbourhood/applyVolunteer",
    name: "applyVolunteer",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/applyVolunteer",
      ], resolve),
  },
  // 时间银行->承诺书
  {
    path: "/futureNeighbourhood/undertaking",
    name: "undertaking",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/undertaking",
      ], resolve),
  },

  // 时间银行->预约完成页面
  {
    path: "/futureNeighbourhood/complete",
    name: "complete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/complete"], resolve),
  },

  // 时间银行->我的家庭
  {
    path: "/futureNeighbourhood/myFamilyList",
    name: "myFamilyList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/myFamilyList",
      ], resolve),
  },

  // 时间银行-新增家庭成员
  {
    path: "/futureNeighbourhood/addFamily",
    name: "addFamily",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/addFamily"], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/activityDetail",
    name: "activityDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityDetail",
      ], resolve),
  },
  // 发布活动
  {
    path: "/futureNeighbourhood/publishActivity",
    name: "publishActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/publishActivity",
      ], resolve),
  },
  //报名提交页面
  {
    path: "/futureNeighbourhood/sureSubmit",
    name: "sureSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/sureSubmit",
      ], resolve),
  },
  //活动工单详情
  {
    path: "/futureNeighbourhood/orderDetail",
    name: "orderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/orderDetail",
      ], resolve),
  },
  //新增联系人
  {
    path: "/futureNeighbourhood/addUser",
    name: "addUser",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/addUser"], resolve),
  },
  //我的报名
  {
    path: "/futureNeighbourhood/myActivityList",
    name: "myActivityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/myActivityList",
      ], resolve),
  },
  //我发布的活动
  {
    path: "/futureNeighbourhood/myPublish",
    name: "myPublish",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/myPublish"], resolve),
  },
  //省级应用-活动广场---------start

  {
    path: "/futureNeighbourhood/activitySquare",
    name: "activitySquare",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/activitySquare/index"], resolve),
  },
  // 时间银行->志愿者申请 volunteerApply
  {
    path: "/futureNeighbourhood/volunteerApply",
    name: "volunteerApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/applyVolunteer",
      ], resolve),
  },
  // 时间银行->承诺书
  {
    path: "/futureNeighbourhood/undertakingApply",
    name: "undertakingApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/undertaking",
      ], resolve),
  },

  // 时间银行->预约完成页面
  {
    path: "/futureNeighbourhood/appointmentComplete",
    name: "appointmentComplete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/activitySquare/complete"], resolve),
  },

  // 时间银行->我的家庭
  {
    path: "/futureNeighbourhood/myFamily",
    name: "myFamily",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/myFamilyList",
      ], resolve),
  },

  // 时间银行-新增家庭成员
  {
    path: "/futureNeighbourhood/addFamilyMember",
    name: "addFamilyMember",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/addFamily",
      ], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/activitySquareDetail",
    name: "activitySquareDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/activityDetail",
      ], resolve),
  },
  // 发布活动
  {
    path: "/futureNeighbourhood/postActivity",
    name: "postActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/publishActivity",
      ], resolve),
  },
  //报名提交页面
  {
    path: "/futureNeighbourhood/confirmSubmit",
    name: "confirmSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/sureSubmit",
      ], resolve),
  },
  //活动工单详情
  {
    path: "/futureNeighbourhood/workOrderDetail",
    name: "workOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/orderDetail",
      ], resolve),
  },
  //新增联系人
  {
    path: "/futureNeighbourhood/addUserContact",
    name: "addUserContact",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/activitySquare/addUser"], resolve),
  },
  //我的报名
  {
    path: "/futureNeighbourhood/myEnroll",
    name: "myEnroll",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/myActivityList",
      ], resolve),
  },
  //我发布的活动
  {
    path: "/futureNeighbourhood/myPublishActivity",
    name: "myPublishActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activitySquare/myPublish",
      ], resolve),
  },
  //省级应用-活动广场---------end
  // 提交社区共建
  {
    path: "/futureNeighbourhood/commonCreate",
    name: "commonCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/commonCreate/index"], resolve),
  },
  //我的共建建议
  {
    path: "/futureNeighbourhood/myCreate",
    name: "myCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/myCreate.vue",
      ], resolve),
  },
  //共建建议详情
  {
    path: "/futureNeighbourhood/createDetail",
    name: "createDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createDetail.vue",
      ], resolve),
  },
  //社区共建墙
  {
    path: "/futureNeighbourhood/createList",
    name: "createList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createList.vue",
      ], resolve),
  },
  // 公益捐赠
  {
    path: "/futureNeighbourhood/donate",
    name: "donate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/index"], resolve),
  },
  //我的捐赠
  {
    path: "/futureNeighbourhood/myDonate",
    name: "myDonate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/myDonate.vue"], resolve),
  },
  //捐赠详情
  {
    path: "/futureNeighbourhood/donateDetail",
    name: "donateDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateDetail.vue"], resolve),
  },
  //我的捐赠详情
  {
    path: "/futureNeighbourhood/myDonateDetail",
    name: "myDonateDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/donate/myDonateDetail.vue",
      ], resolve),
  },
  //捐赠墙
  {
    path: "/futureNeighbourhood/donateList",
    name: "donateList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateList.vue"], resolve),
  },
  //社区达人
  {
    path: "/futureNeighbourhood/talent",
    name: "talent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/index"], resolve),
  },
  //申请社区达人
  {
    path: "/futureNeighbourhood/applyTalent",
    name: "applyTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/applyTalent"], resolve),
  },
  // 省重大应用-社区达人---------start
  {
    path: "/futureNeighbourhood/communityTalent",
    name: "communityTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/communityTalent/index"], resolve),
  },
  //申请社区达人
  {
    path: "/futureNeighbourhood/applyCommunityTalent",
    name: "applyCommunityTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityTalent/applyCommunityTalent",
      ], resolve),
  },
  // 省重大应用-社区达人---------end
  // 和伙人风采列表页
  {
    path: "/futureNeighbourhood/partnerStyleList",
    name: "partnerStyleList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleList.vue",
      ], resolve),
  },
  // 社区达人标题
  {
    path: "/futureNeighbourhood/partnerStyleTitle",
    name: "partnerStyleTitle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleTitle.vue",
      ], resolve),
  },
  // 回复
  {
    path: "/futureNeighbourhood/replyDetail",
    name: "replyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/partner/replyDetail.vue"], resolve),
  },
  // 提交心愿
  {
    path: "/futureNeighbourhood/wishes",
    name: "wishes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/index"], resolve),
  },
  //我的心愿
  {
    path: "/futureNeighbourhood/myWishes",
    name: "myWishes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/myWishes.vue"], resolve),
  },
  //心愿详情
  {
    path: "/futureNeighbourhood/wishDetail",
    name: "wishDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishDetail.vue"], resolve),
  },
  //心愿墙列表
  {
    path: "/futureNeighbourhood/wishList",
    name: "wishList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishList.vue"], resolve),
  },
  //发布居民超话
  {
    path: "/futureNeighbourhood/releaseTopic",
    name: "releaseTopic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/releaseTopic.vue"], resolve),
  },
  //话题列表
  {
    path: "/futureNeighbourhood/topicList",
    name: "topicList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicList.vue"], resolve),
  },
  //话题详情
  {
    path: "/futureNeighbourhood/topicTitle",
    name: "topicTitle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicTitle.vue"], resolve),
  },
  //话题回复
  {
    path: "/futureNeighbourhood/topicReplyDetail",
    name: "topicReplyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/topic/topicReplyDetail.vue",
      ], resolve),
  },
  //明珠互动
  {
    path: "/futureNeighbourhood/interaction",
    name: "interaction",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/interaction/index.vue"], resolve),
  },
  //社群
  {
    path: "/futureNeighbourhood/association",
    name: "association",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/association/index.vue"], resolve),
  },
  //创建社群
  {
    path: "/futureNeighbourhood/createCommunity",
    name: "createCommunity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/createCommunity.vue",
      ], resolve),
  },
  //社群动态
  {
    path: "/futureNeighbourhood/communitDynamic",
    name: "communitDynamic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communitDynamic.vue",
      ], resolve),
  },
  //我的社群
  {
    path: "/futureNeighbourhood/myCommunity",
    name: "myCommunity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/myCommunity.vue",
      ], resolve),
  },
  //编辑社群信息
  {
    path: "/futureNeighbourhood/communityInformationEdit",
    name: "communityInformationEdit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityInformationEdit.vue",
      ], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/communityActivityDetail",
    name: "communityActivityDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityActivityDetail.vue",
      ], resolve),
  },
  //申请消息
  {
    path: "/futureNeighbourhood/communityApplicationInformation",
    name: "communityApplicationInformation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityApplicationInformation.vue",
      ], resolve),
  },
  //申请消息详情
  {
    path: "/futureNeighbourhood/communityApplicationInformationDetail",
    name: "communityApplicationInformationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityApplicationInformationDetail.vue",
      ], resolve),
  },
  //社群成员
  {
    path: "/futureNeighbourhood/communityMembers",
    name: "communityMembers",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityMembers.vue",
      ], resolve),
  },
  //发布活动
  {
    path: "/futureNeighbourhood/releaseActivities",
    name: "releaseActivities",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/releaseActivities.vue",
      ], resolve),
  },
  //发布成功
  {
    path: "/futureNeighbourhood/releaseSuccess",
    name: "releaseSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/releaseSuccess.vue",
      ], resolve),
  },
  //活动列表
  {
    path: "/futureNeighbourhood/communityActivityList",
    name: "communityActivityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityActivityList.vue",
      ], resolve),
  },
  //提交资料
  {
    path: "/futureNeighbourhood/applyJoin",
    name: "applyJoin",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/applyJoin.vue",
      ], resolve),
  },
  //发表动态
  {
    path: "/futureNeighbourhood/publishingTrends",
    name: "publishingTrends",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/publishingTrends.vue",
      ], resolve),
  },
  //社群详情
  {
    path: "/futureNeighbourhood/associationDetail",
    name: "associationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/associationDetail.vue",
      ], resolve),
  },
  // 省级应用-社群社团--------start
  {
    path: "/futureNeighbourhood/associationApply",
    name: "associationApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/index.vue",
      ], resolve),
  },
  //创建社群
  {
    path: "/futureNeighbourhood/createAssociation",
    name: "createAssociation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/createAssociation.vue",
      ], resolve),
  },
  //社群动态
  {
    path: "/futureNeighbourhood/associationDynamic",
    name: "associationDynamic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/associationDynamic.vue",
      ], resolve),
  },
  //我的社群
  {
    path: "/futureNeighbourhood/myCommunityList",
    name: "myCommunityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/myCommunityList.vue",
      ], resolve),
  },
  //编辑社群信息
  {
    path: "/futureNeighbourhood/editCommunityInformation",
    name: "editCommunityInformation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/editCommunityInformation.vue",
      ], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/communityActivitiesDetail",
    name: "communityActivitiesDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/communityActivitiesDetail.vue",
      ], resolve),
  },
  //申请消息
  {
    path: "/futureNeighbourhood/communityApplicationMessage",
    name: "communityApplicationMessage",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/communityApplicationMessage.vue",
      ], resolve),
  },
  //申请消息详情
  {
    path: "/futureNeighbourhood/communityApplicationMessageDetail",
    name: "communityApplicationMessageDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/communityApplicationMessageDetail.vue",
      ], resolve),
  },
  //社群成员
  {
    path: "/futureNeighbourhood/communityMembersApply",
    name: "communityMembersApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/communityMembersApply.vue",
      ], resolve),
  },
  //发布活动
  {
    path: "/futureNeighbourhood/publicationActivity",
    name: "publicationActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/publicationActivity.vue",
      ], resolve),
  },
  //发布成功
  {
    path: "/futureNeighbourhood/publicationSuccess",
    name: "publicationSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/publicationSuccess.vue",
      ], resolve),
  },
  //活动列表
  {
    path: "/futureNeighbourhood/associationActivityList",
    name: "associationActivityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/associationActivityList.vue",
      ], resolve),
  },
  //提交资料
  {
    path: "/futureNeighbourhood/applyToJoin",
    name: "applyToJoin",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/applyToJoin.vue",
      ], resolve),
  },
  //发表动态
  {
    path: "/futureNeighbourhood/releaseDynamics",
    name: "releaseDynamics",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/releaseDynamics.vue",
      ], resolve),
  },
  //社群详情
  {
    path: "/futureNeighbourhood/communityDetail",
    name: "communityDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/associationApply/communityDetail.vue",
      ], resolve),
  },

  // 省级应用-社群社团--------end
  //心愿审核列表
  {
    path: "/futureNeighbourhood/verifyWish",
    name: "verifyWish",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/verifyWish/index.vue"], resolve),
  },
  //心愿审核详情
  {
    path: "/futureNeighbourhood/verifyWishDetail",
    name: "verifyWishDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/verifyWish/verifyWishDetail.vue",
      ], resolve),
  },
  // 安全智护
  {
    path: "/futureNeighbourhood/safetyShield",
    name: "safetyShield",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/safetyShield/index.vue"], resolve),
  },
  // 安全智护申请成功
  {
    path: "/futureNeighbourhood/safetyShieldApplySuccess",
    name: "safetyShieldApplySuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/safetyShield/safetyShieldApplySuccess.vue",
      ], resolve),
  },
  // 安全智护申请
  {
    path: "/futureNeighbourhood/safetyShieldApply",
    name: "safetyShieldApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/safetyShield/safetyShieldApply.vue",
      ], resolve),
  },
  //明珠管家
  {
    path: "/futureNeighbourhood/xiaoheHouseKeeper",
    name: "xiaoheHouseKeeper",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/xiaoheHouseKeeper/index.vue",
      ], resolve),
  },
  //点餐
  {
    path: "/futureNeighbourhood/orderMeal",
    name: "orderMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/orderMeal/index.vue"], resolve),
  },
  //点餐确认订单
  {
    path: "/futureNeighbourhood/toOrderMeal",
    name: "toOrderMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/toOrderMeal.vue",
      ], resolve),
  },
  //点餐成功
  {
    path: "/futureNeighbourhood/orderMealSuccess",
    name: "orderMealSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealSuccess.vue",
      ], resolve),
  },
  //点餐成功
  // {
  //   path: "/futureNeighbourhood/orderMealSuccess",
  //   name: "orderMealSuccess",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureNeighbourhood/orderMeal/orderMealSuccess.vue",
  //     ], resolve),
  // },
  //商家管理订单
  {
    path: "/futureNeighbourhood/bussinessMeal",
    name: "bussinessMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/bussinessMeal.vue",
      ], resolve),
  },
  //点餐订单
  {
    path: "/futureNeighbourhood/orderMealList",
    name: "orderMealList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealList.vue",
      ], resolve),
  },
  // 明珠生活通首页
  {
    path: "/futureNeighbourhood/lifeExpert",
    name: "lifeExpert",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/lifeExpert/index.vue"], resolve),
  },
  // 明珠生活通列表
  {
    path: "/futureNeighbourhood/lifeExpertList",
    name: "lifeExpertList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/lifeExpert/lifeExpertList.vue",
      ], resolve),
  },
  // 明珠生活通查询页面
  {
    path: "/futureNeighbourhood/lifeExpertSearch",
    name: "lifeExpertSearch",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/lifeExpert/lifeExpertSearch.vue",
      ], resolve),
  },
  // 装修贴士-首页
  {
    path: "/futureNeighbourhood/decorationTips",
    name: "decorationTips",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTips/index.vue",
      ], resolve),
  },
  // 装修贴士-详情页
  {
    path: "/futureNeighbourhood/decorationTipsDetail",
    name: "decorationTipsDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTips/decorationTipsDetail.vue",
      ], resolve),
  },
  // 浙里办-装修贴士-start
  {
    path: "/futureNeighbourhood/decorationTipsApply",
    name: "decorationTipsApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTipsApply/index.vue",
      ], resolve),
  },
  // 浙里办-装修贴士-详情页
  {
    path: "/futureNeighbourhood/decorationTipsApplyDetail",
    name: "decorationTipsApplyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTipsApply/decorationTipsDetail.vue",
      ], resolve),
  },
  // 楼宇管理-我的走访
  {
    path: "/futureNeighbourhood/myVisit",
    name: "myVisit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/myVisit.vue",
      ], resolve),
  },
  // 楼宇管理-新增走访
  {
    path: "/futureNeighbourhood/addMyVisit",
    name: "addMyVisit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/addMyVisit.vue",
      ], resolve),
  },
  // 楼宇管理-走访详情
  {
    path: "/futureNeighbourhood/myVisitDetail",
    name: "myVisitDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/myVisitDetail.vue",
      ], resolve),
  },
  // 楼宇管理-发布成功
  {
    path: "/futureNeighbourhood/addMyVisitSuccess",
    name: "addMyVisitSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/addMyVisitSuccess.vue",
      ], resolve),
  },
  // 物业服务首页
  {
    path: "/futureNeighbourhood/propertyServices",
    name: "propertyServices",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/index.vue",
      ], resolve),
  },
  // 物业服务-公告列表
  {
    path: "/futureNeighbourhood/propertyNoticeList",
    name: "propertyNoticeList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyNoticeList.vue",
      ], resolve),
  },
  // 物业服务-公告详情
  {
    path: "/futureNeighbourhood/propertyNoticeDetail",
    name: "propertyNoticeDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyNoticeDetail.vue",
      ], resolve),
  },
  // 物业服务-评分
  {
    path: "/futureNeighbourhood/propertyRat",
    name: "propertyRat",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyRat.vue",
      ], resolve),
  },
  // 红领巾专线
  {
    path: "/futureNeighbourhood/studyExpertLine",
    name: "studyExpertLine",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/index.vue",
      ], resolve),
  },
  // 红领巾专线-签到列表
  {
    path: "/futureNeighbourhood/studySignList",
    name: "studySignList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/studySignList.vue",
      ], resolve),
  },
  // 红领巾专线-新增签到数据
  {
    path: "/futureNeighbourhood/addStudySignInfo",
    name: "addStudySignInfo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/addStudySignInfo.vue",
      ], resolve),
  },

  // 社区直播列表
  {
    path: "/futureNeighbourhood/liveBroadcast",
    name: "liveBroadcast",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/liveBroadcast/index.vue"], resolve),
  },
  // 社区直播-直播详情
  {
    path: "/futureNeighbourhood/liveBroadcastDetail",
    name: "liveBroadcastDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/liveBroadcast/liveBroadcastDetail.vue",
      ], resolve),
  },
];

export default routes;
