const routes = [
  //  联系明珠
  {
    path: "/home/notice",
    name: "notice",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/notice"], resolve),
  },
  //  明珠广播站
  {
    path: "/home/introduce",
    name: "introduce",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/introduce"], resolve),
  },
  {
    path: "/home/manual",
    name: "manual",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/manual"], resolve),
  },
  {
    path: "/home/treaty",
    name: "treaty",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/treaty"], resolve),
  },
  {
    path: "/home/thirdTest",
    name: "thirdTest",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/thirdTest"], resolve),
  },
  {
    path: "/home/login",
    name: "login",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/login"], resolve),
  },
  {
    path: "/home/introducePage",
    name: "introducePage",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/introducePage"], resolve),
  },

  {
    path: "/home/introducePageTwo",
    name: "introducePageTwo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageTwo"], resolve),
  },

  {
    path: "/home/introducePageThree",
    name: "introducePageThree",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageThree"], resolve),
  },

  {
    path: "/home/introducePageFour",
    name: "introducePageFour",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageFour"], resolve),
  },
  {
    path: "/home/introducePageFive",
    name: "introducePageFive",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageFive"], resolve),
  },
  {
    path: "/home/introducePageSex",
    name: "introducePageSex",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageSex"], resolve),
  },
  {
    path: "/home/introducePageSeven",
    name: "introducePageSeven",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageSeven"], resolve),
  },
  {
    path: "/home/introducePageEight",
    name: "introducePageEight",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/introducePageEight"], resolve),
  },
  {
    path: "/home/frontPage",
    name: "frontPage",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/frontPage"], resolve),
  },
  //我的意见
  {
    path: "/home/myAdvice",
    name: "myAdvice",
    meta: {
      title: "我的意见",
    },
    component: (resolve) => require(["@/views/other/myAdvice"], resolve),
  }, //意见反馈
  {
    path: "/home/myAdviceForm",
    name: "myAdviceForm",
    meta: {
      title: "意见反馈",
    },
    component: (resolve) => require(["@/views/other/myAdviceForm"], resolve),
  },
];

export default routes;
